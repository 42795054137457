import React from "react";
import {
  Title,
  Description,
  Link,
  BreakLine,
  Body,
  maxWidth,
} from "../Components/styles";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";
import {
  images,
  covidImages,
  streetView,
} from "./CarouselImages/carouselImages";
import Carousel from "../Components/Carousel/Carousel";
const BioImage = require("./bio_pic.jpg");

const About = () => {
  return (
    <Body>
      <Title>Hacienda Heights Gentle Smiles</Title>
      <Description>
        <div>
          <p>
            Welcome to our Gentle Smiles family! When you visit our office, your
            smile is our <b>top priority</b>. Our goal is to give you
            personalized quality service in a warm and cheerful environment. We
            want to help you maintain your healthy, happy smile for life! Our
            entire team is dedicated to providing you with the personalized,
            gentle care that you deserve.
          </p>
          <p>
            Our Hacienda Heights, CA dental office is located near the freeway
            to make your dentist's visit easy and convenient.
          </p>
          <h2>
            <b>Services that we offer:</b>
          </h2>
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td>
                  <u>General Dentistry:</u>
                  <ul>
                    <li>Adult/Children Dentistry</li>
                    <li>Routine Cleanings</li>
                    <li>Deep Cleanings</li>
                    <li>Digital X-Ray (90% Less Radiation!)</li>
                    <li>Intra-Oral Camera</li>
                    <li>ZOOM In-Office Teeth Whitening</li>
                    <li>Sealants</li>
                    <li>Gum Disease Treatment</li>
                    <li>Root Canal Therapy </li>
                  </ul>
                </td>
                <td>
                  <u>Cosmetic Dentistry:</u>
                  <ul>
                    <li>Veneers </li>
                    <li>Onlay</li>
                    <li>Inlay</li>
                  </ul>
                  <u>Restorative Dentistry</u>
                  <ul>
                    <li>Mercury-Free Fillings</li>
                    <li>Crowns and Bridges</li>
                    <li>Implant Restoration</li>
                    <li>All Types of Removable Dentures and Repairs</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            <Link as={ReactRouterLink} to={"/contact"}>
              Contact our office
            </Link>{" "}
            to schedule an appointment and to make sure your teeth are nice and
            strong!
          </p>

          <p>
            <Link href="tel:6263366401">Please call us</Link> if you have any
            question or simply make an appointment with us to start your path on
            perfect dental health!
          </p>
        </div>
        <BreakLine />
        <Carousel images={[streetView, ...covidImages, ...images]} />
        <BreakLine />
        <div>
          <div style={{ fontSize: "36px" }}>
            <b>Dr. Maria Magdalena Ligad, DDS</b>
          </div>
          <AboutBioContainer>
            <AboutBioImage />
            <div>
              <p>
                Dr. Maria Magdalena Ligad is the owner and sole practitioner of
                Hacienda Heights Gentle Smiles. Dr. Ligad has been in the dental
                field practicing dentistry since she graduated from the
                University of the Philippines in 1992. While she has enjoyed
                working in both hospitals and corporate dental offices, she
                decided to establish Hacienda Heights Gentle Smiles in 2012,
                with the goal of providing excellent dental care to patients in
                the surrounding area.
              </p>
              <p>
                Dr. Ligad takes the time to explain procedures, and works with
                you, the patient, to come up with a treatment plan that works
                best for you. Her primary focus is on creating a treament plan
                that provides the longest lasting, painless, functional, and
                aesthetically most appealing result.
              </p>
              <p>
                Dr. Ligad is an active member of American Dental Association,
                California Dental Association, and San Gabriel Valley Dental
                Society. She is committed to keeping up to date with the most
                current Dental Technology and Procedures through continuing
                education and dental conferences.
              </p>
              <p>
                Dr. Ligad is blessed with 3 children. Her daughter Kate and son
                Adrian are both Software Engineers. Charlie, her youngest son is
                in high school. In her free time, Dr. Ligad likes to spend time
                with family and likes to garden.
              </p>
            </div>
          </AboutBioContainer>
        </div>
      </Description>
    </Body>
  );
};

export default About;

//---------------------------------------------

const AboutBioContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${maxWidth}) {
    flex-direction: column;
  }
`;

const AboutBioImage = styled.img.attrs({
  src: BioImage,
})`
  height: 100%;
  width: 100%;
  margin-right: 32px;
  @media screen and (max-width: ${maxWidth}) {
    height: 50%;
    width: 50%;
  }
`;
