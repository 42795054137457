import React from "react";
import { Body } from "../Components/styles";

const Appointment = () => {
  return (
    <Body>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSfi5hcetVYJ7sXNdnRMJNAeqWKIQ4HC2cHddHOwD4dW-89x6g/viewform?embedded=true"
        width="640"
        height="1088"
        frameBorder={0}
        marginHeight={0}
        marginWidth={0}
        title="Appointment Request"
      >
        Loading…
      </iframe>
    </Body>
  );
};

export default Appointment;

