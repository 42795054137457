import React from "react";
import { Body, Link as StyledLink, maxWidth } from "../Components/styles";
import styled from "styled-components";
const MapImg = require("./Map.png");

const Contact = ({ marginTop }: any) => {
  return (
    <Body marginTop={marginTop} isRow>
      <a
        href="https://goo.gl/maps/2FAHNq7jaNTBSvnj7"
        target="_blank"
        rel="noopener noreferrer"
      >
        <MapImage />
      </a>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h2>Visit Us Today! Tuloy Po Kayo!</h2>
        <p>
          <u>
            <b>Hacienda Heights Gentle Smiles</b>
          </u>
          <br></br>Phone:{" "}
          <StyledLink href="tel:6263366401">(626) 336-6401</StyledLink>
          <br></br>Fax:{" "}
          <StyledLink href="tel:6263368394">(626) 336-8394</StyledLink>
          <br></br>Email:{" "}
          <StyledLink href="mailto:gentlesmiles12@gmail.com">
            gentlesmiles12@gmail.com
          </StyledLink>
          <br></br>
        </p>
        <p>
          <u>
            <b>Hours of Operation</b>
          </u>
          <br></br>Our hours are
          <br></br>Monday, Tuesday, Wednesday 9:30 AM - 6:00 PM
        </p>
      </div>
    </Body>
  );
};

export default Contact;

//---------------------------------------------

const MapImage = styled.img.attrs({
  src: MapImg,
})`
  cursor: pointer;
  height: 400px;
  width: 500px;
  margin-right: 16px;
  @media screen and (max-width: ${maxWidth}) {
    height: 200px;
    width: 300px;
    margin-bottom: 16px;
  }
`;
