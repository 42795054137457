import React from "react";
import {
  Title,
  Description,
  Link,
  BreakLine,
  Body,
} from "../Components/styles";
import { Tooltip } from "../Components/Tooltip";

const Insurance = () => {
  return (
    <Body>
      <Title>Hacienda Heights Gentle Smiles</Title>
      <Description>
        <h2>
          <b>Insurance Information</b>
        </h2>
        <div>
          We accept{" "}
          <Tooltip
            content={
              "All PPO insurances, some HMO namely Cigna and Safeguard.  We are now accepting patients enrolled in Medi-cal."
            }
          >
            most dental insurance plans
          </Tooltip>
          , and for those without insurance, we offer extremely reasonable fees.
          We will help you find out about your insurance benefits and offer
          options for the remaining balance that needs to be paid.
          <p>
            We accept major credit cards, cash, and Care Credit. For those with
            remaining balance, we will happily and efficiently find options that
            will help you the most in paying the balance.
          </p>
        </div>
        <BreakLine />
        <h2>
          <b>Frequently Asked Questions</b>
        </h2>
        <p>
          <b>What are the insurances that you accept?</b>
          <br></br>
          All PPO insurances and Medi-cal.
        </p>
        <p>
          <b>What's PPO?</b>
          <br></br>
          Obtaining services from doctors within the health insurance plan's
          network, called "preferred providers", results in lower fees for
          policyholders; however, out-of-network doctors are still covered.
          Coverage under a preferred provider organization (PPO) requires
          ongoing payment of premiums by policyholders to the insurance company.
        </p>
        <p>
          <b>What's Care Credit?</b>
          <br></br>
          Care Credit is a credit card for health, beauty, and wellness
          expenses.
          <Link href="https://www.carecredit.com/"> Click here</Link> for more
          information and to sign up.
        </p>
      </Description>
    </Body>
  );
};

export default Insurance;
