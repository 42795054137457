import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ScrollToTop } from "./hooks/handleScrollToTop";
import Home from "./Home/Home";
import About from "./About/About";
import Covid from "./Covid/Covid";
import CovidBanner from "./Covid/Banner";
// import Gallery from "./Gallery/Gallery";
import Appointment from "./Appointment/Appointment";
import Contact from "./Contact/Contact";
import Insurance from "./Insurance/Insurance";
import {
  Header,
  HeaderTitle,
  HeaderItems,
  HeaderItem,
  Footer,
  Link as StyledLink,
  tertiaryColor,
  primaryColor
} from "./Components/styles";

const companyName = "Hacienda Heights Gentle Smiles";

const tabs = [
  {
    title: "home",
    link: "/",
    component: <Home />
  },
  {
    title: "covid response",
    link: "/covid",
    component: <Covid />
  },
  {
    title: "about us",
    link: "/about",
    component: <About />
  },
  {
    title: "payment options",
    link: "/insurance",
    component: <Insurance />
  },
  // {
  //   title: "smile gallery",
  //   link: "/smile",
  //   component: <Gallery />
  // },
  {
    title: "appointment request",
    link: "/appointment",
    component: <Appointment />
  },
  {
    title: "contact us",
    link: "/contact",
    component: <Contact />
  }
];

const App: React.FC = (props) => {
  const [shouldShrinkHeader, setShouldShrinkHeader] = useState(false);

  const handleScroll = () => {
    const scrollYPosition = window.scrollY;
    if (scrollYPosition > 50) {
      return setShouldShrinkHeader(true);
    }
    if (scrollYPosition === 0 && shouldShrinkHeader) {
      setShouldShrinkHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <Router>
      <ScrollToTop>
        <Header shouldShrinkHeader={shouldShrinkHeader}>
          <HeaderTitle>
            <Link
              to={"/"}
              style={{ textDecoration: "none" }}
              key={"home"}
            >
              {companyName.toUpperCase()
              }</Link>
          </HeaderTitle>
          <HeaderItems>
            {tabs.map(tab => (
              <Link
                to={tab.link}
                style={{ textDecoration: "none" }}
                key={tab.title}
              >
                <HeaderItem>{tab.title.toUpperCase()}</HeaderItem>
              </Link>
            ))}
          </HeaderItems>
        </Header>
        <Switch>
          {tabs
            .slice()
            .reverse()
            .map(tab => (
              <Route path={tab.link} key={tab.title}>
                {tab.link !== "/covid" && <CovidBanner />}
                {tab.component}
              </Route>
            ))}
        </Switch>
      </ScrollToTop>
      <Footer>
        Hacienda Heights Gentle Smiles
        <br></br>
        Dr. Maria Magdalena Ligad
        <StyledLink
          color={tertiaryColor}
          hoverColor={primaryColor}
          href="https://goo.gl/maps/2FAHNq7jaNTBSvnj7"
          target="_blank"
          rel="noopener noreferrer"
        >
          15925 East Gale Ave. Suite C, Hacienda Heights, CA 91745
        </StyledLink>
        <StyledLink color={tertiaryColor} hoverColor={primaryColor} href="tel:6263366401">
          Please call us: (626)336-6401
        </StyledLink>
      </Footer>
    </Router>
  );
};

export default App;
