import React from "react";
import {
  Title,
  Description,
  Link,
  BreakLine,
  Body,
} from "../Components/styles";
import Protocols from './Protocols'
import Resources from './Resources'
import FAQ from './FAQ'
import { covidImages } from '../About/CarouselImages/carouselImages'
import Carousel from "../Components/Carousel/Carousel";


const Covid = () => {
  return (
    <Body>
      <Title>Our Covid Response</Title>
      <Description>
        <div>
          <BreakLine />
          <h2> Our dedication to your well-being remains unchanged. Our #1 goal is to continue offering you an environment where you feel confident and comfortable. We have diligently worked to implement and maintain rigorous safety measures, ensuring that our office remains a place where your health and safety are paramount.</h2>
          <BreakLine />
          <FAQ />
          <Protocols />
          <BreakLine />
          <Carousel images={covidImages} />
          <BreakLine />
          <Resources />
          <p>
            <Link href="tel:6263366401">Please call us</Link> if you have any
            questions -- if there is no one in the office, please leave your name and number so that we can get back to you as soon as we can.
          </p>
        </div>
      </Description>
    </Body>
  );
};

export default Covid;
