import React from "react";
import Carousel from "../Components/Carousel/Carousel";
import { images as carouselImages } from "./CarouselImages/carouselImages";
import Cards from "./Cards";
import Contact from "../Contact/Contact";
import { Body } from "../Components/styles";

const Home = () => {
  return (
    <Body padding="0%">
      <Carousel images={carouselImages} isMain />
      <Cards />
      <Contact marginTop={"0px"} />
    </Body>
  );
};

export default Home;
