import React from "react";
import { Link } from "../Components/styles";
import { Link as ReactRouterLink } from "react-router-dom";

const FAQ = () => (
  <>
    <p>
      While many things have changed, one thing has remained the same: our
      commitment to your health and safety. Our office follows the infection
      control recommendations from the{" "}
      <Link href="https://success.ada.org/en/practice-management/patients/infectious-diseases-2019-novel-coronavirus">
        American Dental Association (ADA)
      </Link>
      , the{" "}
      <Link href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">
        U.S. Centers for Disease Control and Prevention (CDC)
      </Link>{" "}
      and the{" "}
      <Link href="https://www.osha.gov/SLTC/covid-19/">
        Occupational Safety and Health Administration (OSHA)
      </Link>
      . We are committed to ensuring that our infection control procedures are
      current and effective.
    </p>
    <h3>We are open.</h3>
    <p>
      <Link href="tel:6263366401">Please call us</Link> or{" "}
      <Link as={ReactRouterLink} to={"/appointment"}>
        request an appointment
      </Link>
      , and we will get you on our schedule as soon as possible.
    </p>
    {/* <h3>What should I do in the meantime?</h3>
    <p>
      Dr. Ligad is offering tele-dentistry services during these times.   <Link as={ReactRouterLink} to={"/contact"}>
        Contact</Link> or <Link href="tel:6263366401">call</Link> our office
            if you think you have a dental emergency to ensure that you receive care that shouldn't be postponed.</p>

    <p>
      In the meantime, brush two minutes, twice a day with a fluoride toothpaste and floss regularly. Avoid sugary food and drinks to reduce the risk of cavities.
    The pause in routine dental appointments supports "Shelter at Home" policies and helps "Flatten the Curve". When conditions change, we will again be available to help you maintain your oral health through regular preventive and treatment services.</p>

<h3> Our staff will be using n95 masks instead of surgical masks.</h3>
    <p>
      In dentistry, we are confronted with a shortage of PPE (personal protective equipment) and/or inadequate PPE for this COVID-19 virus.
          </p>
    <p>
      The practice of dentistry involves the use of rotary dental and surgical instruments, such as handpieces or ultrasonic scalers and air-water syringes. These instruments create a visible spray that can contain particle droplets of water, saliva, blood, microorganisms, and other debris. Surgical masks protect mucous membranes of the mouth and nose from droplet spatter, but they do not provide complete protection against inhalation of airborne infectious agents.
          </p> */}
  </>
);

export default FAQ;
