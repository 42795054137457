import React from "react";
import {
  Link
} from "../Components/styles";
import styled from "styled-components";

const FactSheetImg = require("./Images/FactSheet.png");
const HighRiskImg = require("./Images/HighRisk.png");
const StopSpreadImg = require("./Images/StopSpread.png");

const Resources = () => (
  <>
    <h2>
      Resources
           </h2>
    <p>
      <Link href="https://www.cdc.gov/coronavirus/2019-ncov/hcp/dental-settings.html">
        CDC Dental Guidelines For Dental Settings
            </Link>
      <br />
      <Link href="https://www.cda.org/Home/News-and-Events/COVID-19/Infection-Control
">
        CDA Infection Control
            </Link>
      <br />
      <Link href="https://www.cda.org/Home/News-and-Events/Newsroom/Article-Details/health-care-personnel-facing-mask-shortage-in-coronavirus-crisis">
        CDA Dental Mask Shortage Information
            </Link>
    </p>
    <StopSpreadImage />
    <HighRiskImage />
    <FactSheetImage />
  </>
)

export default Resources


//---------------------------------------------

const factSheetMaxWidth = '500px'

const FactSheetImage = styled.img.attrs({
  src: FactSheetImg
})`
  height: 33%;
  width: 33%;
  @media screen and (max-width: ${factSheetMaxWidth}) {
    height: 100%;
    width: 100%;
  }
  `;

const HighRiskImage = styled.img.attrs({
  src: HighRiskImg
})`
  height: 33%;
  width: 33%;
  @media screen and (max-width: ${factSheetMaxWidth}) {
    height: 100%;
    width: 100%;
  }
  `;

const StopSpreadImage = styled.img.attrs({
  src: StopSpreadImg
})`
  height: 33%;
  width: 33%;
  @media screen and (max-width: ${factSheetMaxWidth}) {
    height: 100%;
    width: 100%;
  }
  `;