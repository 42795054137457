import React from "react";
import styled from "styled-components";
import {
  maxWidth,
} from "../Components/styles";
const NewProtocolImg = require("./Images/Protocols.jpeg");

const Protocols = () => (
  <>
    <h3>We have implemented changes to our office procedures</h3>
    <Body>
      <MainText />
      <Content>
        <NewProtocolImage />
      </Content>
    </Body>
  </>
);

const MainText = () => (
  <>
    We will do our best to allow greater time between patients to reduce waiting
    time for you as well reduce the number of patients in the reception area at
    any time. 
  </>
);


export default Protocols;

//---------------------------------------------

const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const NewProtocolImage = styled.img.attrs({
  src: NewProtocolImg,
})`
  height: 60%;
  width: 60%;
  @media screen and (max-width: ${maxWidth}) {
    height: 100%;
    width: 100%;
  }
`;