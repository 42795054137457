import React from "react";
import { Tab } from "../../Components/Carousel/types";
import About from "../../About/About";
// import Gallery from "../../Gallery/Gallery";
import Contact from "../../Contact/Contact";
const ContactImg = require("./contact.jpg");
const AboutImg = require("./about.jpg");
// const GalleryImg = require("./gallery.jpg");

export const cards: Tab[] = [
  {
    link: "/about",
    component: <About />,
    img: {
      src: AboutImg,
      alt: "We are committed to providing gentle, attentive, and quality care.",
      title: "About Us"
    }
  },
  // {
  //   link: "/smile",
  //   component: <Gallery />,
  //   img: {
  //     src: GalleryImg,
  //     alt:
  //       "Feel free to take a look at some of our amazing smile transformations",
  //     title: "Smile Gallery"
  //   }
  // },
  {
    link: "/contact",
    component: <Contact />,
    img: {
      src: ContactImg,
      alt: "We encourage you to contact us with any questions that you may have",
      title: "Contact Us"
    }
  }
];
