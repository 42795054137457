import { Image } from "../../Components/Carousel/types";
const CoupleOne = require("./couple_one.jpeg");
const CoupleTwo = require("./couple_two.jpeg");
const Family = require("./family.jpeg");

export const images: Image[] = [
  {
    src: CoupleOne,
    alt: "A happy smiling couple",
    title: "Healthy Smiles That Will Last"
  },
  {
    src: Family,
    alt: "A happy smiling family",
    title: "Beautiful Smiles For All Ages"
  },
  {
    src: CoupleTwo,
    alt: "A happy smiling couple",
    title: "Smile With Confidence"
  },
];
