import React from "react";
import styled from "styled-components";
import { tertiaryColor, titleFont, primaryColor, maxWidth } from '../Components/styles'
import { Link } from "react-router-dom";



const CovidBanner = () => (
  <Link
    to={"/covid"}
    style={{ textDecoration: "none" }}
    key={"covid"}
  >
    <Body>
      <h2>Our office is open.</h2>
      <h2>We are commited to your health & safety.</h2>
      <h3>Read More</h3>
    </Body>
  </Link>
)

export default CovidBanner

//---------------------------------------------

const Body = styled.div`
  color: ${tertiaryColor};
  text-align: center;
  line-height: 0.5;
  border-bottom: 2px solid #ba8cd7;
  font-family: ${titleFont};
  :hover {
    color: ${primaryColor};
  }
  @media screen and (max-width: ${maxWidth}) {
    line-height: 1;
  }
`;
