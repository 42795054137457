import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import {
  Button,
  titleFont,
  primaryColor,
  secondaryColor,
  maxWidth,
} from "../styles";
import { Link } from "react-router-dom";
import { Image } from "./types";
import styled, { keyframes } from "styled-components";

interface Props extends RouteComponentProps {
  images: Image[];
  isMain?: boolean;
}
const Carousel: React.FC<Props> = ({ images, isMain }) => {
  const gotoImage = (i?: number) => {
    if (i === -1) {
      curPicIndex === 0
        ? setCurPicIndex(images.length - 1)
        : setCurPicIndex(curPicIndex - 1);
    } else if (i || i === 0) {
      setCurPicIndex(i);
    } else {
      curPicIndex === images.length - 1
        ? setCurPicIndex(0)
        : setCurPicIndex(curPicIndex + 1);
    }
  };

  const [curPicIndex, setCurPicIndex] = useState<number>(
    Math.floor(Math.random() * (images.length - 1 - 0 + 1)) + 0
  );
  const curPic = images[curPicIndex];

  return (
    <>
      <CarouselContainer isMain={isMain}>
        <CarouselSlides isMain={isMain}>
          <CarouselImg src={curPic.src} />
        </CarouselSlides>
        {curPic.title && (
          <CarouselTitleContainer index={curPicIndex} isMain={isMain}>
            {curPic.title}
            {isMain && (
              <Link
                to={"/appointment"}
                style={{ textDecoration: "none" }}
                key={"appointment"}
              >
                <Button fontSize="16px" shouldFill={true}>
                  Make An Appointment
                </Button>
              </Link>
            )}
          </CarouselTitleContainer>
        )}

        <CarouselButtonLeft onClick={() => gotoImage(-1)}>
          {String.fromCharCode(10094)}
        </CarouselButtonLeft>
        <CarouselButtonRight onClick={() => gotoImage()}>
          {String.fromCharCode(10095)}
        </CarouselButtonRight>
        <CarouselDotContainer>
          {images.map((image, i) => (
            <CarouselDot
              key={image.src}
              onClick={() => gotoImage(i)}
              isActive={i === curPicIndex}
            ></CarouselDot>
          ))}
        </CarouselDotContainer>
      </CarouselContainer>
    </>
  );
};

export default withRouter(Carousel);

//---------------------------------------------

const backgroundColor = "#f8f8f8";

interface CarouselContainerProps {
  readonly isMain?: boolean;
}
const CarouselContainer = styled.div<CarouselContainerProps>`
  width: 100%;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  background-color: ${backgroundColor};
  overflow: hidden;
  @media screen and (max-width: ${maxWidth}) {
    grid-column-start: 1;
    grid-column-end: 4;
    height: ${(props) => (props.isMain ? "300px" : "600px")};
  }
`;

const breath = keyframes`
  0% {
    height: 100%;
    width: 100%;
  }
  50% {
    height: 110%;
    width: 110%;
  }
  75% {
    height: 100%;
    width: 100%;
  }
  100% {
    height: 110%;
    width: 110%;
  }
`;

interface CarouselImgProps {
  readonly src: string;
}
const CarouselImg = styled.img.attrs((props) => ({
  src: props.src,
}))<CarouselImgProps>`
  animation: ${breath} 20s linear infinite;
`;

interface CarouselSlidesProps {
  readonly isMain?: boolean;
}
const CarouselSlides = styled.div<CarouselSlidesProps>`
  width: 100%;
  height: 100%;
  max-width: ${(props) => (props.isMain ? maxWidth : "500px")};
  display: flex;
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: ${(props) => (props.isMain ? "1" : "2")};
  grid-column-end: ${(props) => (props.isMain ? "4" : "3")};
  justify-self: center;
  align-self: center;
  @media screen and (max-width: ${maxWidth}) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
`;

interface CarouselTitleContainerProps {
  readonly index: number;
  readonly isMain?: boolean;
}

const CarouselTitleContainer = styled.div<CarouselTitleContainerProps>`
  grid-row: 3;
  grid-column: 2;
  font-weight: 700;
  font-size: ${(props) => (props.isMain ? "40px" : "15px")};
  width: ${(props) => {
    if (!props.isMain) {
      return "60%";
    }
    return "100%";
  }};
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: ${titleFont};
  align-self: center;
  justify-self: ${(props) => {
    if (!props.isMain) {
      return "center";
    }
    const x = props.index;
    switch (true) {
      case x === 0:
        return "start";
      case x % 2 === 0:
        return "end";
      default:
        return "center";
    }
  }};
  background-color: ${(props) => {
    if (!props.isMain) {
      return backgroundColor;
    }
  }};
  border-radius: 5px;
  margin-bottom: 25px;
  @media screen and (max-width: ${maxWidth}) {
    margin-bottom: 40px;
    width: 80%;
    font-size: 20px;
    grid-row: 3;
    grid-column-start: 1;
    grid-column-end: 4
    justify-self: center;
  }
`;

const CarouselButtonLeft = styled.a`
  grid-row: 2;
  grid-column: 1;
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 16px;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 3px 3px;
  user-select: none;
  align-self: center;
  justify-self: start;
  text-align: center;
  :hover  {
    background-color: ${secondaryColor};
  }
  @media screen and (max-width: ${maxWidth}) {
    color: ${primaryColor};
  }
`;

const CarouselButtonRight = styled(CarouselButtonLeft as any)`
  grid-column: 3;
  justify-self: end;
`;

const CarouselDotContainer = styled.div`
  grid-column: 2;
  grid-row: 3;
  align-self: end;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

interface CarouselDotProps {
  readonly isActive: boolean;
}

const CarouselDot = styled.span<CarouselDotProps>`
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: ${(props) => (props.isActive ? primaryColor : "#bbb")};
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  :active,: hover {
    background-color: ${primaryColor};
  }
`;
