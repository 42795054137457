import React from "react";
import { cards } from "./CardImages/cardDetails";
import { Link } from "react-router-dom";
import {
  Button,
  titleFont,
  bodyFont,
  maxWidth
} from "../Components/styles";
import styled from "styled-components";


const Cards = () => {
  return (
    <HomeCardContainer>
      {cards.map((card) => (
        <Link
          to={card.link}
          style={{ textDecoration: "none" }}
          key={card.img.title}
        >
          <HomeCard>
            <HomeCardImg src={card.img.src} />
            <HomeCardTitleContainer>
              <HomeCardTitle>{card.img.title}</HomeCardTitle>
              <HomeCardDescription>{card.img.alt}</HomeCardDescription>
              <Button
                height={"30px"}
                width={"100px"}
                fontSize={"15px"}
                padding={"5px 5px"}
                shouldFill={true}
              >
                Learn More
                </Button>
            </HomeCardTitleContainer>
          </HomeCard>
        </Link>
      ))}
    </HomeCardContainer>
  );
};

export default Cards;

//---------------------------------------------

export const HomeCardContainer = styled.div`
  padding: 60px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: ${maxWidth}) {
    flex-wrap: wrap;
    padding: 20px 0px;
    margin: 0px;
  }
`;

export const HomeCard = styled.div`
  filter: grayscale(30%);
  margin-right: 20px;
  height: 200px;
  width: 300px;
  display: grid;
  border-radius: 30px;
  opacity: 0.7;
  transition: 0.5s ease;
  :hover {
    opacity: 0.95;
  }
  @media screen and (max-width: ${maxWidth}) {
    width: 100%
    opacity: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
    height: 100%
  }
`;

export const HomeCardTitleContainer = styled.div`
  background: white;
  width: 100%;
  opacity: 0.7;
  height: 70%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  grid-column: 1;
  grid-row: 1;
  :hover {
    opacity: 0.85;
  }
  @media screen and (max-width: ${maxWidth}) {
    opacity: 0.85;
    height: 100%;
    padding: 16px 0px;
  }
`;

interface HomeCardImgProps {
  readonly src: string;
}
export const HomeCardImg = styled.img<HomeCardImgProps>`
  grid-column: 1;
  grid-row: 1;
  src: ${props => props.src};
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

export const HomeCardTitle = styled.div`
  color: black;
  font-family: ${titleFont};
  font-size: 40px;
  font-weight: 600;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  @media screen and (max-width: ${maxWidth}) {
    font-size: 20px;
    font-weight: 600;
  }
`;

export const HomeCardDescription = styled.div`
  color: black;
  font-family: ${bodyFont};
  font-weight: 700;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  @media screen and (max-width: ${maxWidth}) {
    font-size: 16px;
    font-weight: 600;
    padding: 0px 24px;
  }
`;

