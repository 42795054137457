import React, { useState } from "react";
import { TooltipContainer, TooltipText } from "./styles";

interface TooltipProps {
  children: any;
  content: string;
}

export const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  const [shouldShowTooltip, setShouldShowTooltip] = useState<boolean>(false);
  return (
    <TooltipContainer
      onMouseOver={() => setShouldShowTooltip(true)}
      onMouseLeave={() => setShouldShowTooltip(false)}
    >
      <TooltipText isShown={shouldShowTooltip}>{content}</TooltipText>
      {children}
    </TooltipContainer>
  );
};
