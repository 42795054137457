import { Image } from "../../Components/Carousel/types";
const Chair = require("./chair.jpg");
const CovidFront = require("./covid_front.jpg");
const CovidWaiting = require("./covid_waiting.jpg");
const CovidWaiting2 = require("./covid_waiting_2.jpg");
const Front = require("./front.jpg");
const Front2 = require("./front_2.jpg");
const FullGearSitting = require("./full_gear_sitting.jpg");
const FullGearStanding = require("./full_gear_standing.jpg");
const FullGearFront = require("./full_gear_front.jpg");
const Room = require("./room.jpg");
const Street = require("./street_view.jpeg");
const DoorWithSign = require("./door_with_sign.jpg");
const StreetWithSign = require("./street_with_sign.jpg");

export const streetView: Image = {
  src: Street,
  alt: "Street view",
  title: "Our office is located at 15925 E Gale Ave Ste C, Hacienda Heights CA",
};

export const images: Image[] = [
  {
    src: Front,
    alt: "Front of the office",
    title: "Our reception area pre-covid",
  },
  {
    src: Front2,
    alt: "Front of the office",
    title: "Our reception area pre-covid",
  },
  {
    src: Room,
    alt: "Dental rooms",
    title: "Our rooms pre-covid",
  },
  // {
  //     src: Xray,
  //     alt: "X-ray room",
  //     title: "Our x-ray room"
  // },
];

export const covidImages: Image[] = [
  {
    src: CovidFront,
    alt: "Reception during covid",
    title:
      "We now have a barrier in our reception area to prevent droplet contamination",
  },
  {
    src: CovidWaiting,
    alt: "Reception during covid",
    title: "No magazines or coffee in the waiting area to reduce surfaces",
  },
  {
    src: CovidWaiting2,
    alt: "Reception during covid",
    title: "Limited chairs in the waiting area to allow for social distancing",
  },
  {
    src: Chair,
    alt: "Chair during covid",
    title:
      "We've placed more barriers on the chair and have a true HEPA air filter",
  },
  {
    src: FullGearSitting,
    alt: "PPE covid",
    title: "The doctor & staff will now have head to toe PPE",
  },
  {
    src: FullGearStanding,
    alt: "PPE covid",
    title: "The doctor & staff will now have head to toe PPE",
  },
  {
    src: FullGearFront,
    alt: "PPE covid",
    title: "The doctor & staff will now have head to toe PPE",
  },
];
