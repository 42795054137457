import styled from "styled-components";

export const bodyFont = "'Quicksand', sans-serif";
export const titleFont = "'Montserrat', sans-serif";
export const primaryColor = "#ba8cd7";
export const secondaryColor = "#cb8cd7";
export const tertiaryColor = "#800080";
export const maxWidth = '900px';

export const Title = styled.div`
  text-align: center;
  color: black;
  font-family: ${titleFont};
  font-size: 48px;
  font-weight: 700;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  margin: 24px auto;
`;
export const Description = styled.div`
  color: black;
  font-family: ${bodyFont};
  font-size: 16px;
`;

interface ButtonProps {
  readonly width?: string;
  readonly height?: string;
  readonly shouldFill?: boolean;
  readonly fontSize?: string;
  readonly padding?: string;
}
export const Button = styled.button<ButtonProps>`
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "auto")};
  color: ${props => (props.shouldFill ? "white" : primaryColor)};
  background: ${props => (props.shouldFill ? primaryColor : "white")}
  font-size: ${props => (props.fontSize ? props.fontSize : "20px")};
  font-family: ${bodyFont};
  padding: ${props => (props.padding ? props.padding : "0.25em 1em")};
  border: 2px solid ${primaryColor};
  border-radius: 3px;
  cursor: pointer;
  margin: 8px 0px;
  :hover {
    color: ${props => (props.shouldFill ? "black" : primaryColor)};
    border: 2px solid ${primaryColor};
    background: ${props => (props.shouldFill ? primaryColor : "white")}
  }
`;

interface LinkProps {
  readonly href?: string;
  readonly color?: string;
  readonly hoverColor?: string;
  readonly activeColor?: string;
}

export const Link = styled.a<LinkProps>`
  cursor: pointer;
  href: ${props => (props.href ? props.href : undefined)}
  color: ${props => (props.color ? props.color : "palevioletred")};
  text-decoration: none;
  :hover {
    color: ${props => (props.hoverColor ? props.hoverColor : primaryColor)};
  }
  :active {
    color: ${props =>
    props.activeColor ? props.activeColor : "palevioletred"};
  }
`;

export const BreakLine = styled.hr`
  display: block;
  margin: 24px auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  :hover {
    cursor: pointer;
  }
`;

interface TooltipTextProps {
  readonly isShown: boolean;
}
export const TooltipText = styled.div<TooltipTextProps>`
  visibility: ${props => (props.isShown ? "visible" : "hidden")};
  background-color: gray;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  width: 200px;
  top: 100%;
  left: 50%;
  margin-left: -60px;
`;

//---------------------------------------------

interface HeaderProps {
  shouldShrinkHeader: boolean;
}
export const Header = styled.header<HeaderProps>`
  top: 0;
  position: sticky;
  display: grid;
  background-color: ${props =>
    props.shouldShrinkHeader ? "#d8bfd8" : "white"};
  border-bottom: 2px solid ${primaryColor};
  padding: ${props => (props.shouldShrinkHeader ? "15px 0px" : "45px 0px 45px 20px")};
  color: black;
  width: 100%;
  transition: 0.2s;
  font-family: ${bodyFont};
  grid-template-columns: 18% auto;
  @media screen and (max-width: ${maxWidth}) {
    padding-top: 20px;
    padding-bottom: 20px;
    grid-template-columns: 25% auto;
  }
`;

export const HeaderTitle = styled.h1`
  font-family: ${titleFont};
  font-weight: 600;
  font-size: 20px;
  grid-column-start: col-start 0;
`;

export const HeaderItems = styled.ul`
list-style-type: none;
align-self: center;
justify-self: center;
`;

export const HeaderItem = styled.li`
  font-size: 15px;
  margin-right: 15px;
  color: black;
  display: inline;
  :hover {
    color: ${primaryColor};
  }
  :active {
    color: palevioletred;
  }
`;

//---------------------------------------------

interface BodyProps {
  padding?: string;
  marginTop?: string;
  isRow?: boolean;
}
export const Body = styled.div<BodyProps>`
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.padding ? props.padding : "0px 200px")};
  display: flex;
  flex-direction: ${props => (props.isRow ? "row" : "column")};
  margin-top: ${props => (props.marginTop ? props.marginTop : "20px")}
  @media screen and (max-width:  ${props => props.isRow ? "800px" : maxWidth}) {
    padding: 10px;
    flex-direction: column;
  }
`;

//---------------------------------------------

export const Footer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: white;
  border-bottom: 2px solid #f5f5f5;
  padding-top: 30px;
  padding-bottom: 30px;
  color: black;
  width: 100%;
  font-family: ${bodyFont};
  background-color: #d8bfd8;
  margin-top: 16px;
  @media screen and (max-width: ${maxWidth}) {
    display: flex;
    flex-wrap: wrap;
  }
`;


